<template>
    <v-snackbar
        v-model="snackbar"
        :bottom="true"
        :color="color"
    >
        {{ message }}

        <template v-slot:action="{ attrs }">
            <c-btn
                v-bind="attrs"
                :outlined="false"
                nopx
                text
                :title="$t('common.close')"
                @click="hideSnackbar"
            />
        </template>
    </v-snackbar>
</template>
<script>
export default {
    components: {
        CBtn: () => import('@/components/base/c-btn')
    },
    computed: {
        snackbar: {
            get() {
                return this.$store.getters['snackbar/getStatus']
            },
            set(value) {
                return this.$store.dispatch('snackbar/hideSnackbar')
            }
        },
        message() {
            return this.$store.getters['snackbar/getMessage']
        },
        color() {
            return this.$store.getters['snackbar/getColor']
        }
    },
    methods: {
        hideSnackbar() {
            this.$store.dispatch('snackbar/hideSnackbar')
        }
    }

}
</script>
